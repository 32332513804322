import PropTypes from 'prop-types';
import { useCallback } from 'react';
import styled from '@emotion/styled';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import { useTheme, alpha as hexAlpha } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Grid, Stack, Button, MenuItem, Typography } from '@mui/material';

import { varAlpha } from 'src/theme/styles';

import Chart, { useChart } from 'src/components/chart';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export function TrafficGraph({
  filterValue,
  setFilterValue,
  filterLabel,
  setFilterLabel,
  title,
  subheader,
  total,
  chart,
  ...other
}) {
  const theme = useTheme();
  const { themeMode } = useSettingsContext();
  const popover = usePopover();

  const handleChangeFilter = useCallback(
    (newValue) => {
      setFilterValue(newValue?.value);
      setFilterLabel(newValue?.label);
    },
    [setFilterLabel, setFilterValue]
  );

  const chartSeries = chart.series.map((item) => item.value);
  const chartSeriesCounts = chart.series.map((item) => item);

  const chartColors = chart.colors ?? [
    [hexAlpha(theme?.palette?.success?.light, 0.8), hexAlpha(theme?.palette?.success?.main, 0.8)],
    [hexAlpha(theme?.palette?.warning?.light, 0.8), hexAlpha(theme?.palette?.warning?.main, 0.8)],
    [hexAlpha(theme?.palette?.error?.light, 0.8), hexAlpha(theme?.palette?.error?.main, 0.8)],
  ];

  const chartOptions = useChart({
    chart: { sparkline: { enabled: true } },
    colors: chartColors.map((color) => color[1]),
    labels: chart.series.map((item) => item.label),
    stroke: { width: 0 },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: chartColors.map((color) => [
          { offset: 0, color: color[0], opacity: 1 },
          { offset: 100, color: color[1], opacity: 1 },
        ]),
      },
    },
    grid: { padding: { top: -40, bottom: -40 } },
    plotOptions: {
      radialBar: {
        hollow: { margin: 10, size: '40%' },
        track: { margin: 10, background: varAlpha(theme?.vars?.palette?.grey['500Channel'], 0.08) },
        dataLabels: {
          total: {
            show: true,
            label: 'All',
            formatter: () => total,
          },
          value: { offsetY: 2, fontSize: theme?.typography?.h5?.fontSize },
          name: { show: true },
        },
      },
    },

    ...chart.options,
  });

  const StyledDot = styled(Box)(() => ({
    width: 12,
    height: 12,
    flexShrink: 0,
    display: 'flex',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'currentColor',
  }));
  const series = [
    { label: 'Today', value: 'TODAY' },
    { label: 'This Week', value: 'WEEK' },
    { label: 'This Month', value: 'MONTH' },
    { label: 'This Year', value: 'YEAR' },
    { label: 'Life Time', value: 'LIFETIME' },
  ];

  return (
    <Card {...other} className="dashboard-radio-bar-chart">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CardHeader title={title} subheader={subheader} />
        <Stack mr={3} pt={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              onClick={popover.onOpen}
              sx={{
                border: '0.5px solid #D3D3D3',
                width: 'auto',
              }}
            >
              <Typography variant="caption" fontSize={14}>
                {filterLabel}
              </Typography>
              &nbsp;&nbsp;
              {popover?.open ? (
                <KeyboardArrowUpIcon fontSize="14px" />
              ) : (
                <KeyboardArrowDownIcon fontSize="14px" />
              )}
            </Button>
            <CustomPopover
              anchorEl={popover.anchorEl}
              open={popover.open}
              onClose={popover.onClose}
              arrow="top-right"
              sx={{ width: 150 }}
            >
              {series?.map((item, key) => (
                <MenuItem
                  key={key}
                  selected={item?.value === filterValue}
                  onClick={() => {
                    popover.onClose();
                    handleChangeFilter(item);
                  }}
                >
                  {item?.label}
                  <Divider sx={{ mt: 0.2 }} />
                </MenuItem>
              ))}
            </CustomPopover>
          </Stack>
        </Stack>
      </Stack>

      <Chart
        key={total}
        type="radialBar"
        series={chartSeries}
        options={chartOptions}
        height={filterValue === 'LIFETIME' ? 415 : '415px'}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" p={3} px={3}>
        <Box>
          {chartOptions?.labels?.map((item, key) => (
            <Grid display="flex" alignItems="center" key={key}>
              <StyledDot component="span" sx={{ color: chartOptions?.colors[key] }} /> &nbsp; &nbsp;
              <Typography
                variant="subtitle2"
                color={themeMode === 'dark' ? '#D3D3D3' : 'rgba(28, 37, 46, 1)'}
                py={0.8}
              >
                {item}
              </Typography>
            </Grid>
          ))}
        </Box>
        <Box>
          {chartSeriesCounts?.map((item, index) => (
            <Stack key={index} spacing={1}>
              <Typography
                variant="caption"
                color={themeMode === 'dark' ? '#D3D3D3' : 'rgba(28, 37, 46, 1)'}
                fontSize="14px"
                py={0.8}
              >
                {item?.counts}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Stack>
    </Card>
  );
}
TrafficGraph.propTypes = {
  title: PropTypes.any,
  subheader: PropTypes.any,
  total: PropTypes.any,
  chart: PropTypes.any,
  setFilterValue: PropTypes.any,
  filterValue: PropTypes.any,
  filterLabel: PropTypes.any,
  setFilterLabel: PropTypes.any,
};
