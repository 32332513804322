import { useMemo } from 'react';

import BentoIcon from '@mui/icons-material/Bento';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import RateReviewIcon from '@mui/icons-material/RateReview';
import DescriptionIcon from '@mui/icons-material/Description';
import StyleRoundedIcon from '@mui/icons-material/StyleRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import LineStyleRoundedIcon from '@mui/icons-material/LineStyleRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import VerticalSplitRoundedIcon from '@mui/icons-material/VerticalSplitRounded';
// import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';

import { paths } from 'src/routes/routes/paths';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  // sidebar data block
  const data = useMemo(
    () => [
      {
        subheader: 'Overview',
        // eslint-disable-next-line no-sparse-arrays
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
          {
            title: 'Inventory',
            path: `${paths.dashboard.inventory.root}`,
            icon: icon('ic_kanban'),
            children: [
              {
                title: 'Products',
                path: paths.dashboard.inventory.root,
                // icon: <Iconify icon="solar:cart-4-bold-duotone" />,
                icon: icon('ic_ecommerce'),
              },
              {
                title: 'Category',
                path: paths.dashboard.inventory.category,
                icon: <CategoryRoundedIcon fontSize="small" />,
              },
              {
                title: 'Brand',
                path: paths.dashboard.inventory.brand,
                icon: <LabelImportantRoundedIcon fontSize="small" />,
              },
              {
                title: 'Collections',
                path: paths.dashboard.inventory.collection,
                icon: <StyleRoundedIcon fontSize="small" />,
              },

              {
                title: 'Special Sale',
                path: paths.dashboard.inventory.special_sale,
                icon: <ElectricBoltRoundedIcon fontSize="small" />,
              },
              {
                title: 'Product Attributes',
                path: paths.dashboard.inventory?.product_attributes,
                icon: <WorkspacesIcon fontSize="small" />,
              },
              {
                title: 'Product Group',
                path: paths.dashboard.inventory.product_group,
                icon: <TableViewRoundedIcon fontSize="small" />,
              },
            ],
          },
          {
            title: 'Site Settings',
            path: `${paths.dashboard.settings.root}`,
            icon: <Iconify icon="solar:settings-bold-duotone" />,
            children: [
              {
                title: 'Finance Attributes',
                // path: paths.dashboard.settings.finance,
                path: '#',
                icon: icon('ic_invoice'),
                children: [
                  {
                    title: 'Tax Category',
                    path: paths?.dashboard?.settings?.finance,
                    icon: <VerticalSplitRoundedIcon fontSize="small" />,
                    disabled: false,
                  },
                  {
                    title: 'Currency',
                    path: paths?.dashboard?.settings?.finance,
                    icon: <ViewQuiltRoundedIcon fontSize="small" />,
                    disabled: false,
                  },
                  // {
                  //   title: 'Other Settings',
                  //   path: `#`,
                  //   icon: <ViewListRoundedIcon fontSize="small" />,
                  //   disabled: true,
                  // },
                ],
              },
              {
                title: 'Banner',
                path: paths.dashboard.settings.banners,
                icon: <BentoIcon fontSize="small" />,
              },
              {
                title: 'Preferences',
                path: `#`,
                icon: <AddchartRoundedIcon fontSize="small" />,
                children: [
                  {
                    title: 'Header Data',
                    path: paths?.dashboard?.settings?.preference?.header,
                    icon: <VerticalSplitRoundedIcon fontSize="small" />,
                    disabled: false,
                  },
                  {
                    title: 'Footer Data',
                    path: paths?.dashboard?.settings?.preference?.footer,
                    icon: <ViewQuiltRoundedIcon fontSize="small" />,
                    disabled: false,
                  },
                  // {
                  //   title: 'Other Settings',
                  //   path: `#`,
                  //   icon: <ViewListRoundedIcon fontSize="small" />,
                  //   disabled: true,
                  // },
                ],
              },
              {
                title: 'Group',
                path: paths?.dashboard?.settings.groups,
                icon: <DashboardRoundedIcon fontSize="small" />,
                disabled: false,
              },

              {
                title: 'Pickup Location',
                path: paths?.dashboard?.settings.pickupLocation,
                icon: <PinDropRoundedIcon fontSize="small" />,
                disabled: false,
              },
              // {
              //   title: 'Delivery Options',
              //   path: paths?.dashboard?.settings?.delivery,
              //   icon: <LocalShippingRoundedIcon fontSize="small" />,
              //   disabled: false,
              // },
              {
                title: 'Payment Options',
                path: paths?.dashboard?.settings?.payment,
                icon: <CreditScoreRoundedIcon fontSize="small" />,
                disabled: false,
              },
              {
                title: 'Coupon',
                path: paths.dashboard.settings.coupon,
                // path: `#`,

                icon: <PercentIcon fontSize="small" />,
                // disabled: true,
              },
              {
                title: 'Pages',
                path: paths.dashboard.settings.pages,
                icon: <LayersRoundedIcon fontSize="small" />,
                disabled: false,
              },
              // {
              //   title: 'Sections',
              //   path: `#`,
              //   icon: <LineStyleRoundedIcon fontSize="small" />,
              //   disabled: true,
              // },
              {
                title: 'Blogs',
                path: paths?.dashboard?.settings?.blogs,
                icon: <DescriptionIcon fontSize="small" />,
                disabled: false,
              },
              {
                title: 'Settings',
                path: paths.dashboard.settings.root,
                icon: <SettingsIcon fontSize="small" />,
                disabled: false,
              },
            ],
          },
          {
            title: 'Administration',
            path: paths?.dashboard.administration.root,
            icon: icon('ic_user'),
            children: [
              {
                title: 'Users',
                path: paths.dashboard.administration.users,
                icon: <GroupRoundedIcon fontSize="small" />,
                disabled: false,
              },
              {
                title: 'Members',
                path: `#`,
                icon: <GroupAddRoundedIcon fontSize="small" />,
                disabled: true,
              },
              {
                title: 'Member Roles',
                path: `#`,
                icon: <AssignmentIndRoundedIcon fontSize="small" />,
                disabled: true,
              },
            ],
          },

          {
            title: 'Orders',
            path: paths?.dashboard?.orders,
            icon: icon('ic_order'),
            disabled: false,
          },
          {
            title: 'Reviews',
            path: paths?.dashboard?.reviews,
            icon: <RateReviewIcon fontSize="small" />,
            disabled: false,
          },
          ,
        ],
      },
    ],
    []
  );

  return data;
}

// const  icon('ic_dashboard'),
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
