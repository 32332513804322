import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import svgImg from 'src/assets/logo/shape-square.svg';
import { varAlpha, bgGradient } from 'src/theme/styles';
import { decimal } from 'src/server/http/axiosInstance';

import SvgColor from 'src/components/svg-color';

export function MainWidgets({
  icon,
  title,
  total,
  count,
  color,
  bgColor,
  price,
  isSmall,
  sx,
  subtitleValue,
  ...other
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        ...bgGradient({
          color: `135deg, ${varAlpha(
            theme?.vars?.palette[color]?.lighterChannel,
            0.48
          )}, ${varAlpha(theme?.vars?.palette[color]?.lightChannel, 0.48)}`,
        }),
        boxShadow: 'none',
        position: 'relative',
        color,
        background: `linear-gradient(${bgColor})`,
        ...sx,
      }}
      {...other}
    >
      {isSmall ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box pl={2} pt={1}>
            <Box sx={{ width: 48, height: 48, mb: 1.5 }}>{icon}</Box>
            <Box sx={{ mb: 1, mt: 3, typography: 'subtitle2' }}>
              {title}&nbsp; {total}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pr={1.5}>
            <Box
              sx={{ flexGrow: 1, minWidth: 112 }}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Typography variant="subtitle2" pt={1}>
                {subtitleValue > 0 ? 'Stock Out' : 'In Stock'}
                &nbsp;
              </Typography>
              {subtitleValue > 0 ? <Box sx={{ typography: 'h4' }}>{subtitleValue}</Box> : ''}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box pl={2} pt={1}>
            <Box sx={{ width: 48, height: 48, mb: 1.5 }}>{icon}</Box>
            <Box sx={{ mb: 1, mt: 3, typography: 'subtitle2' }}>{title}</Box>
          </Box>
          <Box display="flex" alignItems="center" pr={1.5}>
            <Box
              sx={{ flexGrow: 1, minWidth: 112 }}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Typography variant="subtitle2" pt={1}>
                {price && 'OMR'} &nbsp;
              </Typography>
              <Box sx={{ typography: 'h4' }}>{count || total?.toFixed(decimal) || 0}</Box>
            </Box>
          </Box>
        </Box>
      )}

      <SvgColor
        src={svgImg}
        sx={{
          top: 0,
          left: -20,
          width: 280,
          zIndex: -1,
          height: 240,
          opacity: 0.15,
          position: 'absolute',
          color: `${color}.main`,
        }}
      />
    </Card>
  );
}

MainWidgets.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  sx: PropTypes.object,
  price: PropTypes.bool,
  isSmall: PropTypes.bool,
  subtitleValue: PropTypes.number,
};
